<template>
    <div class="p-3" @click="clicked">
        <span v-text="title" class="text-2x"></span>
        <b class="ml-2 text-primary" v-text="description"></b>
    </div>
</template>
<script>

export default {
    name: 'LogoComponent',
    props: {
        title: { type: String, default: 'Catalist' },
        description: { type: String, default: 'Our Powerful Component' }
    },
    methods: {
        clicked () {
            console.log('Logo Clicked');
            this.$emit('clicked');
        }
    }
};
</script>
