<template>
    <div class="fl-te-c z-index-max bg-2 bb-1 px-3" >
        <logo-component :description="description" :title="title" @clicked="logoClicked" />
        <div>
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>

import LogoComponent from '@components/LogoComponent.vue';

export default {
    name: 'TopBar',
    components: { LogoComponent },
    props: {
        title: {
            type: String,
            default: 'Catalist'
        },
        description: {
            type: String,
            default: 'Our Powerful Component'
        }
    },

    methods: {
        logoClicked () {
            console.log('asdad');
            this.$emit('logo-clicked');
        }
    }
};

</script>
