<template>
    <div>
        <div class="p-3 bg-4-h bb-1" v-for="item in items" :key="item.id" @click="selectItem(item)">
            <a style="text-decoration: none" href="javascript:void(0);" :class="{'text-primary': selected && item.id === selected.id}">
                <b>{{ item.name }}</b>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        items: { type: Array, required: true }
    },
    data () {
        return {
            selected: null
        };
    },

    methods: {
        selectItem (item) {
            this.selected = item;
            this.$emit('select', item);
        }
    }
};
</script>
