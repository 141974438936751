<template>
    <div class="fl-x fl-gr-1">
        <div :class="leftClassesNames">
            <slot name="left-box"></slot>
        </div>
        <div class="fl-gr-1 fl-y">
            <div class="fl-gr-1" style="overflow: scroll; max-height: 100%" :class="topBoxClass">
                <slot name="top-box"></slot>
            </div>

            <div :class="bottomBoxClass">
                <slot name="bottom-box"></slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        leftBoxWidth: { type: String, default: '20r' },
        leftBoxClass: { type: String, default () { return 'p-2 br-1'; } },
        bottomBoxClass: { type: String, default () { return 'p-2 bt-1'; } },
        topBoxClass: { type: String, default () { return 'p-2 fl-y'; } }
    },
    computed: {
        leftClassesNames () {
            const names = ['fl-y', this.leftBoxClass];
            names.push('w-' + this.leftBoxWidth);
            return names.join(' ');
        }
    }
};
</script>
