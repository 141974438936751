<template>
    <chat-box-layout class="full-height" left-box-class="br-1">
        <template #left-box>
            <logo-component class="bb-1" description="Emails" />

            <div class="fl-gr-1 bg-1">
                <side-bar-item :items="bots" :selected="selected" @select="selectBot"/>
            </div>
        </template>

        <template #top-box>

            <div class="container mt-4 fl-gr-1" v-if="selected">
                <div v-if="response" class="fl-y">
                    <div class="fl-x">
                        <btn design="basic-b" size="xs" icon="fa fa-copy" class="mr-2" @click="copySubject" title="Copy Subject"/>
                        <b class="fl-gr-1" v-html="response.subject"></b>
                    </div>

                    <div class="mt-4 text-justify bt-1 pt-4 fl-gr-1 fl-x-t">
                        <btn design="basic-b" size="xs" icon="fa fa-copy" class="mr-2" @click="copyMessage" title="Copy Email Body"/>
                        <div class="fl-gr-1" v-html="response.message"></div>
                    </div>
                </div>

                <div v-else-if="performing">
                    <div class="bg-info card fl-x">
                        <loading-animation/>
                        <span class="ml-2">Doing the AI Magic...</span>
                    </div>
                </div>
                <div v-else>
                    <div class="bg-info card">Please enter the content.</div>
                </div>
            </div>

            <div class="container mt-4 fl-y" v-else>
                <div class="bg-info card">Please select a Email Type</div>
            </div>

            <div class="bg-warning p-4 mb-2" v-if="response">
                This is a AI generated Email. Please do not just copy and paste it. Make sure to review it before sending it out.
            </div>
        </template>

        <template #bottom-box v-if="selected">
            <validated-text-area class="bg-2" v-model="prompt" textarea-classes="min-h-20r" :disabled="performing" @blur="perform"/>
        </template>
    </chat-box-layout>
</template>
<script>
import ChatBoxLayout from '@components/layouts/ChatBoxLayout.vue';
import showdown from 'showdown';
import axios from 'secure-axios';
import LogoComponent from '@components/LogoComponent.vue';
import SideBarItem from '@components/SideBarItem.vue';
import urls from '../../data/urls';

const converter = new showdown.Converter();

function mmd (src) {
    return converter.makeHtml(src);
}

function copyToClipboard (text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        return navigator.clipboard.writeText(text);
    } else {
        // Fallback for older browsers
        var textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }
}

export default {
    name: 'Emails',
    components: { SideBarItem, LogoComponent, ChatBoxLayout },
    data () { return { bots: [], performing: false, selected: null, prompt: '', response: '' }; },
    async mounted () {
        try {
            const response = await axios.get(urls.bots.listBots);
            this.bots = response.data.response;
            console.log(this.bots);
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        selectBot (bot) {
            this.selected = bot;
        },

        async perform () {
            if (this.performing) return;
            if (!this.prompt) return;

            this.response = null;
            this.performing = true;
            const id = this.selected.id;
            const headers = { 'Content-Type': 'text/plain' };
            const config = { headers: headers };
            const response = await axios.post(urls.bots.performBots + id + '/', this.prompt, config);
            const message = response.data;
            const index = message.indexOf('\n');
            this.response = {
                subject: message.substring(0, index),
                message: mmd(message.substring(index + 1))
            };
            this.performing = false;
        },

        copySubject () {
            copyToClipboard(this.response.subject);
        },

        copyMessage () {
            copyToClipboard(this.response.message);
        }
    }
};
</script>
