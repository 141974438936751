<template>
    <div class="p-2 container mb-3">
        <b>
            <span v-if="isUser">User</span>
            <span v-else>Catalist</span>
        </b>
        <p v-html="message"/>
    </div>
</template>

<script>
export default {
    name: 'ChatBubble',
    props: {
        message: {
            type: String,
            required: true
        },
        isUser: {
            type: Boolean,
            default: false
        }
    }
};
</script>
