<template>
    <div class="fl-x full-height">
        <div class="fl-y w-20r br-1">
            <top-bar description="Projects" @logo-clicked="goToProjects" />

            <!-- Sidebar -->
            <side-bar-item class="bt-1" :items="options" @select="selectItem"/>
        </div>
        <div class="fl-gr-1">
            <div class="fl-te-c m-3">
                <div v-if="project">
                    <b v-html="project.name"></b> <br/>
                    <small v-html="project.client"></small>
                </div>

                <div class="btn-group">
                    <btn text="Generate" :loading="generating" loading-text="Generating..." @click="generate()" v-if="prompt" icon="fa fa-search" design="animated-a"/>
                    <btn text="Save" :loading="saving" loading-text="Saving..." @click="save()" icon="fa fa-save" v-if="content"/>
                </div>
            </div>
            <div class="mx-3">
                <div class="bg-info card m-4" v-if="id === 0">
                    Please select an option.
                </div>
                <div class="row mb-5" v-else>
                    <div class="col-md-7">
                        <div class="mt-1">
                            <markdown-editor v-model="content" style="border: 0;"/>
                        </div>
                    </div>
                    <div class="col-md-5 fl-y">
                        <div class="mt-1">
                            <validated-text-area placeholder="Enter your ask here" v-model="question" @keyup.enter="questionAsked" />
                        </div>
                        <div class="fl-gr-1">
                            <div class="bg-info card fl-x" v-if="answering">
                                <loading-animation class="mr-2" />
                                Loading the answer
                            </div>
                            <markdown-editor v-model="answer" v-else-if="answer" style="border: 0;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '@components/TopBar.vue';
import urls from '@/data/urls';
import axios from 'secure-axios';
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import SideBarItem from '@components/SideBarItem.vue';

const names = {
    1: 'requirements',
    2: 'polished_requirement',
    3: 'srs',
    4: 'functional_document',
    5: 'risk_document',
    6: 'database_design',
    7: 'screen_list'
};

const options = [
    { id: 1, name: 'Requirements' },
    { id: 2, name: 'Polished Requirement' },
    { id: 3, name: 'SRS Document' },
    { id: 4, name: 'Functional Document' },
    { id: 5, name: 'Risk Document' },
    { id: 6, name: 'Database Design' },
    { id: 7, name: 'Screen List' }
];

const personas = {
    1: 'Business Analyst',
    2: 'Business Analyst',
    3: 'Business Analyst',
    4: 'Business Analyst',
    5: 'Business Analyst',
    6: 'Database Designer',
    7: 'Business Analyst'
};

const prompts = {
    2: 'Requirement Polisher',
    3: 'SRS Builder',
    4: 'Functional Requirement Generation',
    5: 'Risk Document Generation',
    6: 'Database Designer',
    7: 'Screen List Builder'
};

export default {
    components: {
        SideBarItem,
        MarkdownEditor,
        TopBar
    },

    data () {
        return {
            options,

            // Flags
            answering: false,
            saving: false,
            generating: false,
            question: '',
            answer: '',

            id: 0,
            project: null,
            projectId: 0,

            // LLM Parameters
            persona: '',
            content: '',
            prompt: '',

            requirements: '',
            name: 'Nothing selected'
        };
    },
    async created () {
        this.projectId = this.$route.params.id;
        await this.getProject();
    },

    methods: {
        async getProject () {
            const projectId = this.projectId;
            const data = { id: projectId };
            const response = await axios.form(urls.projects.get, data);
            this.project = response.data.project;
        },

        async performLLMPrompt (prompt = this.prompt, persona = this.persona, requirements = this.requirements, question = '', assistant = '') {
            const data = { persona, prompt, requirements, question, assistant };
            const response = await axios.form(urls.projects.dynamic, data);
            return response.data;
        },

        async generate (prompt = this.prompt, persona = this.persona, requirements = this.requirements) {
            if (!prompt) return;
            this.generating = true;
            this.content = await this.performLLMPrompt(prompt, persona, requirements);
            this.generating = false;
        },

        async questionAsked (evt) {
            if (!evt.ctrlKey) {
                console.log('Action not allowed. Press Ctrl + Enter to ask the question.');
                return;
            }

            this.answering = true;
            this.answer = await this.performLLMPrompt(this.prompt, this.persona, this.content, this.question);
            this.answering = false;
        },

        async save () {
            const id = this.id;
            if (!id) return;

            const name = names[id];
            const project = this.project;
            const content = this.content;

            if (!content) return;

            // No changes
            if (project[name] === content) return;

            project[name] = content;
            this.saving = true;
            const data = { project: project.id, name, content };
            const response = await axios.form(urls.projects.saveDocument, data);
            console.log(response.data);
            console.log(data);
            this.saving = false;
        },

        async selectItem (item) {
            await this.save();

            const project = this.project;
            const id = item.id;
            const name = names[id];

            this.id = id;
            this.name = item.name;
            this.content = project[name] || '';
            this.answer = '';

            if (id > 1) {
                this.persona = personas[id];
                this.prompt = prompts[id];
                this.requirements = project.polished_requirements || project.requirements;
            } else {
                this.prompt = 0;
                this.requirements = '';
                this.persona = '';
            }
        },

        goToProjects () {
            console.log('asasdasdad');
            this.$router.push({ name: 'ProjectList' });
        }

    }
};
</script>
